<template>
  <div class="roleInfo">
    <a-modal v-model="visible" @ok="handleOk" okText="确定" cancelText="取消">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="反馈数量">
          <a-input-number :min="0" style="width:300px" placeholder="请输入添加调研反馈数量" v-model="Num" />
        </a-form-item>
      </a-form>
    </a-modal>
    <div class="actionBar">
      <a-space
        ><CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>
          <a-range-picker
          style="width: 280px"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
          @change="timeChange"
        >
        </a-range-picker>
        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 350px"
          allowClear
          @search="onSearch"
      /></a-space>
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        v-if="handLists.top.indexOf('add') != -1"
      >
        添加调研</a-button
      >
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          v-model="record.list_order"
          style="width:100px"
          :min="0"
          @focus="saveOldNum(text)"
          @blur="setChangeVal(record)"
        />
      </span>

      <span slot="status" slot-scope="text, record">
        <a-popover placement="topLeft">
          <template slot="content">
            {{ text == 1 ? "点击禁用" : "点击启用" }}
          </template>
          <a-tag
            :color="text == 1 ? 'green' : '#999'"
            @click="AsetStatus(record.id, text == 1 ? 2 : 1)"
          >
            {{ text == 1 ? "启用" : "禁用" }}
          </a-tag>
        </a-popover>
      </span>

      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('brush') != -1">
           <FontIcon
            @click.native="brush(record.id)"
            title="刷调研反馈"
            type="icon-add-fill"
            size="22"
          ></FontIcon>
           <a-divider type="vertical" />
        </span>


        <span
          v-if="
            record.published_time == 0 &&
              handLists.right.indexOf('publish') != -1
          "
        >
          <a-popconfirm
            title="发布后将不可修改！"
            ok-text="确定"
            cancel-text="取消"
            @confirm="publish(record)"
          >
            <FontIcon title="发布" type="icon-fabu" size="22"></FontIcon>
          </a-popconfirm>
          <a-divider type="vertical" />
        </span>

        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
          v-if="
            record.published_time == 0 && handLists.right.indexOf('edit') != -1
          "
        ></FontIcon>
        <FontIcon
          v-else
          @click.native="look(record)"
          title="查看调研详情"
          type="icon-chakan"
          size="22"
        ></FontIcon>
        <span v-if="handLists.right.indexOf('del') != -1">
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
          </a-popconfirm>
        </span>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  surveyIndex,
  surveyDelete,
  uploadImage,
  surveyChangeVal,
  surveySetStatus,
  surveyDataPublish,
  answerBrushAnswer
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "调研ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
    width: 100,
  },
  {
    title: "标题",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "简介",
    dataIndex: "desc",
    scopedSlots: { customRender: "desc" },
  },
  {
    title: "开始时间",
    dataIndex: "start_time",
    scopedSlots: { customRender: "start_time" },
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    width: 120,
  },
   {
    title: "结束时间",
    dataIndex: "end_time",
    scopedSlots: { customRender: "end_time" },
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    width: 120,
  },
  {
    align: "center",
    title: "设置状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width: 100,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 180,
  },
];

export default {
  data() {
    return {
      locale,
      uploadImage,
      columns,
      data: [],
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        company_id: undefined,
        start_time:'',
        end_time:''
      },
      count: 0,
      oldNum: "",
      handLists: this.$store.state.menuHandleList,

      visible: false,
      Num:0,
      surverId:'',
      
      time:[]
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getSurveyIndex();
  },
 
  methods: {
    timeChange(a) {
      if (a.length > 0) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
     this.getSurveyIndex();
    },
    handleOk(){
      if(this.Num==0){
        this.$message.error('反馈数量必须大于0')
        return false
      }
      answerBrushAnswer({id:this.surverId,num:this.Num}).then(res=>{
        if(res.code==1){
          this.$message.success('成功')
          this.visible=false
        }
      })
    },
    brush(id){
      this.surverId=id
      this.visible=true
      this.Num=''
    },
    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getSurveyIndex();
    },

    saveOldNum(text) {
      this.oldNum = text;
    },

    AsetStatus(id, val) {
      surveySetStatus({
        ids: id,
        status: val,
      }).then((res) => {
        if (res.code == 1) {
          this.getSurveyIndex();
          this.$message.success("状态修改成功");
        }
      });
    },
    publish(rec) {
      surveyDataPublish({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.getSurveyIndex();
        }
      });
    },

    setChangeVal(rec) {
      if (this.oldNum == rec.list_order) {
        return false;
      } else {
        surveyChangeVal({ id: rec.id, change_value: rec.list_order }).then(
          (res) => {
            if (res.code == 1) {
              this.getSurveyIndex();
              this.$message.success("排序修改成功");
            }
          }
        );
      }
    },
    showModal() {
      this.$router.push({
        path: "/datamaintenances/AddEditSurvey",
      });
    },
    editMenu(rec) {
      this.$router.push({
        path: "/datamaintenances/AddEditSurvey",
        query: { id: rec.id },
      });
    },
    look(rec) {
      this.$router.push({
        path: "/datamaintenances/AddEditSurvey",
        query: { id: rec.id, look: "1" },
      });
    },

    confirm(rec) {
      surveyDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSurveyIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSurveyIndex();
    },
    getSurveyIndex() {
      surveyIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSurveyIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getSurveyIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}
</style>
